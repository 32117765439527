.container_box {
  position: absolute;
  left: 240px;
  width: calc(100% - 240px);
  right: 0;
  top: 10%;
  padding-top: 20px;
}

.sidebar_component {
  position: absolute;
  left: 0px;
  width: 240px;
  top: 0px;
  height: 100%;
  background-image: linear-gradient(#b92c82, #3a0ca3);
}

.sidebar_component>* {
  color: #fff;
}

.header_component {
  /* width: 100%; */
  background-color: #fff;
  height: 70px;
  box-shadow: none;
}

.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected,
.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: transparent;
  opacity: 1;
}

.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
  opacity: 0.5;
  transition: 0.3s all;
}

.css-10hburv-MuiTypography-root {
  font-weight: 400;
  font-size: 16px;
}

.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected .css-10hburv-MuiTypography-root {
  font-weight: 500;
  transition: 0.3s all;
}

.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root:hover .css-10hburv-MuiTypography-root {
  font-weight: 500;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  background: transparent;
  color: transparent;
  border: 2px solid var(--unnamed-color-6c6c6d);
  border-radius: 50%;
}

.css-117w1su-MuiStepIcon-text {
  fill: var(--unnamed-color-6c6c6d);
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  background: transparent linear-gradient(180deg,
      var(--unnamed-color-b92c82) 0%,
      var(--unnamed-color-3a0ca3) 100%) 0% 0% no-repeat padding-box;
  border-radius: 50%;
  color: transparent;
  border: none;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: var(--unnamed-color-3a0ca3);
  border: var(--unnamed-color-3a0ca3);
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active .css-117w1su-MuiStepIcon-text {
  fill: #fff;
}

.css-zpcwqm-MuiStepConnector-root {
  top: 10px;
  height: 5px;
  background: var(--unnamed-color-6c6c6d);
}

.downloadAppImage {
  width: 65%;
}

.wingImage {
  margin-left: auto;
}

.MuiInputBase-root.MuiOutlinedInput-root {
  font-size: 15px !important;
}

.recharts-legend-item-text {
  font-size: 12px !important;
}

.recharts-layer {
  color: #000 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
}

.css-mbha63-MuiStepLabel-label {
  line-height: 0.55 !important;
}

.css-mhc70k-MuiGrid-root {
  width: 100% !important;
}

.css-1f8bwsm img {
  width: 20px;
}

/* .css-1c06rxt-MuiTypography-root {
  font-size: 15px;
  font-weight: 300;
} */
/* .css-1wozefu-MuiButtonBase-root-MuiListItemButton-root{
  opacity: 0.5;
} */
.css-1wozefu-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
  background-color: rgba(255, 255, 255, 0.2);
  opacity: 1;
}

.MuiLinearProgress-root {
  background-color: #feeef7;
}

@media only screen and (max-width: 900px) {
  .downloadAppImage {
    width: 60%;
  }

  .wingImage {
    margin-left: inherit;
    margin: auto;
  }
}

@media only screen and (max-width: 600px) {
  .downloadAppImage {
    width: 70%;
  }

  .wingImage {
    margin-left: auto;
    margin-left: inherit;
    margin: auto;
  }
}

.custom-stepper-root {
  background-color: transparent;
  /* Replace with your desired background color */
}

.custom-stepper-label.Mui-active p {
  font-weight: 700;
  color: #000;
  /* Replace with your desired dot color */
}

.MuiSvgIcon-root.Mui-active {
  color: transparent;
  background: transparent linear-gradient(180deg, #b92c82 0%, #3a0ca3 100%) 0% 0% no-repeat padding-box;
  border-radius: 50%;
}

.MuiSvgIcon-root.Mui-completed {
  color: #3a0ca3;
}

.MuiStepIcon-text {
  font-size: 12px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

/* Create a custom icon using ::before pseudo-element */
input[type="date"]::after {
  content: "\f073";
  /* Unicode value of the Font Awesome icon or any other icon library */
  font-family: "Font Awesome";
  /* Use the font family that contains the custom icon */
  color: #fff;
  /* Replace 'red' with your desired icon color */
  margin-right: 5px;
  /* Optional: Add some spacing between the icon and the date */
}

.MuiListItemButton-root.Mui-disabled {
  pointer-events: all;
  cursor: not-allowed;
}

/* datepicker-custom.css */

/* Date picker container */
.react-datepicker-wrapper {
  display: inline-block;
}

/* Date picker input field */
.react-datepicker__input-container input {
  font-size: 12px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: transparent;
}

.registration-datepicker .react-datepicker__input-container {
  height: 50px;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
}

.registration-datepicker .react-datepicker__input-container input {
  font-size: 14px;
  outline: none;
  background-color: transparent;
  font-family: "ROBOTO";
}

.registration-datepicker .react-datepicker-wrapper {
  width: 100%;
  margin-top: 0px;
}

.react-datepicker__input-container input::placeholder {
  color: #fff;
}

.registration-datepicker .react-datepicker__input-container input::placeholder {
  color: #7e7e7e;
}

/* Date picker popup container */
.react-datepicker__portal {
  background-color: rgba(0, 0, 0, 0.5);
}

/* Date picker calendar */
.react-datepicker {
  font-family: Arial, sans-serif;
}

/* Date picker header */
.react-datepicker__header {
  background-color: #007bff;
  color: #fff;
}

.react-datepicker__header__dropdown {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Date picker navigation arrows */
.react-datepicker__navigation {
  background-color: transparent;
}

/* Date picker day cells */
.react-datepicker__day {
  border-radius: 50%;
}

/* Date picker selected day */
.react-datepicker__day--selected {
  background: transparent linear-gradient(90deg, #b92c82 0%, #3a0ca3 100%) 0% 0% no-repeat padding-box;
  color: #fff;
}

.react-datepicker__day:hover {
  background-color: #b92c82;
  border-radius: 50%;
  cursor: pointer;
}

.react-datepicker__day--disabled:hover {
  background-color: grey;
  border-radius: 50%;
  cursor: not-allowed;
}

/* Date picker today's date */
.react-datepicker__day--today {
  font-weight: bold;
}

/* Date picker day names */
.react-datepicker__day-names {
  display: none;
}

.custom-datepicker-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #fff;
  padding: 3px;
  border-radius: 8px;
}

.custom-datepicker-input {
  width: 100px;
  font-size: 16px;
  border: 1px solid #ccc;
  outline: none;
  flex: 1;
}

.registration-datepicker .custom-datepicker-input {
  padding: 5px 10px;
  font-size: 15px;
  height: 45px;
}

.custom-datepicker-icon {
  padding: 0px;
  cursor: pointer;
}

.circle {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}

.half {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

.top {
  background-color: transparent;
  /* Color for the upper half */
  clip-path: polygon(0 0, 100% 0, 100% 65%, 0 65%);
  border: 9px solid #ff33cc;
}

.bottom {
  background-color: transparent;
  /* Color for the lower half */
  border: 9px solid #6e2f9d;
  clip-path: polygon(0 70%, 100% 70%, 100% 100%, 0 100%);
}

.timer {
  position: absolute;
  z-index: 9999;
  margin: 0px;
  font-size: 9px;
  top: 7px;
  left: 0px;
  font-weight: 700;
}

.recharts-cartesian-axis-line,
.recharts-cartesian-axis-tick-line {
  display: none;
}

.recharts-text {
  color: #fff !important;
}

.recharts-legend-item-text {
  color: #5d5c5c !important;
  font-weight: 700 !important;
}

.vertical-line {
  transform: rotate(90deg);
  border: none;
  border-top: 2px solid #d1cfcf;
  /* Change the color as needed */
  width: 20px;
  /* Adjust the width as needed */
}

.react-datepicker__year-dropdown-container {
  width: 100%;
  margin: 0px;
}

.react-datepicker__year-select {
  background: #3b3b3b;
  border: none;
  color: #fff;
  /* width: 100%; */
}

.react-datepicker__current-month {
  color: #fff;
}

.react-tel-input {
  width: 100%;
  margin-top: 0px;
  padding: 0px;
  display: flex;
}

.business_mobile .react-tel-input {
  width: 10%;
  margin-top: 0px;
  padding: 0px;
  display: flex;
}

.react-tel-input .form-control {
  width: 100%;
  height: 50px;
  padding: 0;
  padding-left: 50%;
}

.business_mobile .react-tel-input .form-control {
  font-size: 14px;
  border: none;
  padding-left: 40%;
}

.react-tel-input .selected-flag {
  padding-left: 5px;
}

.business_mobile .react-tel-input .selected-flag {
  padding-left: 0px;
}

.react-tel-input .selected-flag .flag {
  position: absolute;
  top: 30%;
  margin-top: 0px;
}

.react-tel-input .arrow {
  display: none;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border: none;
  box-shadow: none;
}

.MuiAutocomplete-root .MuiFormLabel-root,
.MuiAutocomplete-popper {
  font-size: 15px !important;
}

.react-datepicker__header {
  background-color: #3b3b3b;
  color: #fff;
}

.react-datepicker__month-container {
  background: #3b3b3b;
}

.react-datepicker__day {
  border-radius: 50%;
  color: #fff;
}

.react-datepicker__day--disabled {
  color: #ababab;
}

.MuiButtonBase-root-MuiRadio-root.Mui-checked,
.MuiButtonBase-root.MuiRadio-root {
  padding: 0px;
}

.file-container {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #e0e0e0;
  color: #333;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  margin-top: 30px;
  /* height: 200px; */
}

/* Style the text inside the container */
.file-label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
}

/* Style the actual file input */
.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  /* height: 200px; */
}

.whiteDatePicker {
  color: #fff;
}

.ButtonWithBadge:hover {
  background-color: #dd4b8e;
  color: #fff;
}

.whiteBackground {
  background-color: #fff;
}

.textAreaCustom {
  width: 100%;
  padding: 10px;
  border: 1px solid grey;
  box-shadow: 0px 0px grey;
  border-radius: 8px;
}

.custom-datepicker {
  width: 100%;
}

.small-datepicker {
  width: 95%;
  height: 50px;
}

.custom-datepicker label {
  color: #fff;
}

.black-datepicker label {
  color: #000;
  /* top: -5px; */
}

.small-datepicker label {
  font-size: 12px;
  color: #fff;
  /* top: -5px; */
}

.custom-datepicker input {
  color: #fff;
}

.black-datepicker input {
  color: #000;
}

.small-datepicker input {
  height: 50px;
  font-size: 12px;
  padding: 0px 5px;
}

.custom-datepicker .MuiOutlinedInput-notchedOutline {
  border-color: #fff;
  box-shadow: "0px 3px 10px #0000000D";
}

.black-datepicker .MuiOutlinedInput-notchedOutline {
  border-color: #cbcbcb;
  border-radius: 8px;
}

.small-datepicker .MuiOutlinedInput-notchedOutline {
  height: 50px;
}

.black-datepicker .MuiOutlinedInput-notchedOutline {
  height: 57px;
}

.custom-datepicker button svg {
  color: #fff;
}

.black-datepicker button svg {
  color: #a1a1a1;
}

.small-datepicker button svg {
  width: 20px;
}

.year-only-datepicker {
  margin-top: 8px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.MuiInputBase-root::first-letter {
  text-transform: capitalize;
}

.small-datepicker .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #fff;
}

.small-datepicker .MuiFormLabel-root.Mui-focused {
  color: #fff;
}

.small-datepicker .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #fff;
}

.recharts-legend-item {
  width: 100%;
  display: flex !important;
}

.recharts-default-legend {
  margin: auto !important;
  width: max-content !important;
}

/* Display the dollar sign when the input is focused via keyboard navigation */
.dollar-input:focus-visible {
  border: none;
  outline: none;
}

input[type="file"].file-upload {
  position: absolute;
  top: 0px;
  right: 0;
  height: 50px;
  opacity: 0;
  width: 200px;
  cursor: pointer;
}

.custom-file-upload {
  display: inline-block;
  padding: 3px 5px;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.file-upload-container {
  position: relative;
  border: 1px solid #bbbbbb;
  height: 50px;
  border-radius: 7px;
  box-shadow: 0px 3px 10px #0000000D,
}

.MuiSlider-markLabel {
  font-size: 12px;
}

.btn-group .select {
  position: relative;
}

.btn-group .select input:checked+label {
  background-color: #B92C82;
  border-radius: 8px;
}

.btn-group .select input:checked+label:hover,
.btn-group .select input:checked+label:focus,
.btn-group .select input:checked+label:active {
  background-color: #B92C82;
  border-radius: 8px;
}

.btn-group .select input:checked+label p,
.btn-group .select input:checked+label:focus p,
.btn-group .select input:checked+label p {
  color: #fff;
}

.btn-group .select input {
  opacity: 0;
  position: absolute;
}

.btn-group .select .button_select {
  margin: 0 10px 10px 0;
  display: flex;
  background-color: transparent;
}

.btn-group .select .button_select:hover,
.btn-group .select .button_select:focus,
.btn-group .select .button_select:active {
  background-color: transparent;
}

.option {
  position: relative;
}

.option input {
  opacity: 0;
  position: absolute;
}

.option input:checked+span {
  background-color: #ffc107;
}

.option input:checked+span:hover,
.option input:checked+span:focus,
.option input:checked+span:active {
  background-color: #ffc107;
}

.option .btn-option {
  margin: 0 10px 10px 0;
  display: flex;
  background-color: transparent;
}

.option .btn-option:hover,
.option .btn-option:focus,
.option .btn-option:active {
  background-color: transparent;
}

.small-table .MuiTableCell-root {
  padding: 9px;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  font-size: 15px;
}

.globalTableSelect .MuiInputBase-root {
  border: none;
  /* height: auto; */
}

.globalTableSelect .MuiInputLabel-root {
  top: -7px;
}

.selectionButton:hover {
  background-color: #e643a7;
  color: #fff;
}

.activeSelectionButton {
  background-color: #B92C82;
  color: #fff;
}

.CircularProgressbar-path {
  stroke: #6e2f9d !important;
  stroke-linecap: square !important;
}

.CircularProgressbar-trail {
  stroke: #ff33cc !important;
}

.CircularProgressbar-text {
  fill: #000 !important;
  font-size: 17px !important;
  font-weight: 800 !important;
}

.closed .CircularProgressbar-path {
  stroke: #8f0404c0 !important;
  stroke-linecap: square !important;
}

.closed .CircularProgressbar-trail {
  stroke: #8f0404c0 !important;
}

.closed .CircularProgressbar-text {
  fill: #8f0404c0 !important;
  font-size: 20px !important;
}

.pending .CircularProgressbar-path {
  stroke: #e4710cc0 !important;
  stroke-linecap: square !important;
}

.pending .CircularProgressbar-trail {
  stroke: #e4710cc0 !important;
}

.pending .CircularProgressbar-text {
  fill: #e4710cc0 !important;
  font-size: 17px !important;
}

.rejected .CircularProgressbar-path {
  stroke: #bfbebe !important;
  stroke-linecap: square !important;
}

.rejected .CircularProgressbar-trail {
  stroke: #bfbebe !important;
}

.rejected .CircularProgressbar-text {
  fill: #bfbebe !important;
  font-size: 17px !important;
}

.whitetText p,
.whitetText span {
  color: #fff !important
}

.whitetText div p,
.whitetText div span {
  color: #000 !important
}

.CircularProgressbar {
  width: 70px !important;
}

.DisabledFormControl {
  background-color: #eeeeee;
}

.notification-dialogue-show {
  position: absolute;
  top: 75px;
  right: 5px;
  width: 300px;
  background: #fff;
  color: grey;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #c1c1c1;
}
.notification-dialogue-hide {
  position: absolute;
  border: 1px solid;
  border-color: #000;
  top: 70px;
  right: 0;
  width: 300px;
  background: #fff;
  color: grey;
  display: none;
}