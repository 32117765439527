@font-face {
  font-family: Roboto;
  src: url(assets/fonts/Roboto-Regular.ttf);
  /* font-weight: normal; */
}
@font-face {
  font-family: CustomFont;
  src: url(assets/fonts/Roboto-Thin.ttf);
  font-weight: normal;
}

/* Colors: */
:root {
  --unnamed-color-6c63ff: #6c63ff;
  --unnamed-color-fbfbfb: #fbfbfb;
  --unnamed-color-f9fafc: #f9fafc;
  --unnamed-color-f1f4ff: #f1f4ff;
  --unnamed-color-feeef7: #feeef7;
  --unnamed-color-bcbcbc: #bcbcbc;
  --unnamed-color-6c6c6d: #6c6c6d;
  --unnamed-color-000000: #000000;
  --unnamed-color-6d8395: #6d8395;
  --unnamed-color-0b2244: #0b2244;
  --unnamed-color-3a0ca3: #3a0ca3;
  --unnamed-color-b92c82: #b92c82;
  --unnamed-color-ffffff: #ffffff;
}
body {
  margin: 0;
  padding: 0;
  font-family: Roboto !important;
  color: #000000;
  overflow-x: hidden !important;
}
a {
  font-size: 0.75rem;
  /* color: #3A0CA3; */
  text-decoration: none;
  /* font-weight: bold; */
  text-transform: capitalize;
  line-height: inherit;
}
